<template>
  <section class="transfer cheque">
    <h2 class="heading">ATM Pickup</h2>

    <form
      class="form"
      @submit.prevent="submitForm"
      novalidate="true"
      autocomplete="off"
    >
      <section class="deposit__to">
        <div class="heading-four">
          <h2>Withdraw from</h2>
          <p>Source account</p>
        </div>
        <div>
          <div class="sources-two">
            <div class="sources-two__wrapper">
              <div
                v-for="account in userCardAccounts"
                :key="account.NUBAN"
                @click="$emit('sourceDetails', account)"
                class="sources-two__card"
              >
                <label :for="account.NUBAN" class="sources-two__left">
                  <p>{{ account.NUBAN }} | {{ account.ACCT_TYPE }}</p>
                  <p>
                    {{ account.WorkingBalance | formatCurrencyTwo }}
                    {{ account.Currency_Code }}
                  </p>
                  <p>{{ account.AccountDescp | capitalize }}</p>
                </label>
                <input
                  type="radio"
                  :value="account"
                  :id="account.NUBAN"
                  name="deposit-account"
                  class="sources-two__right"
                  v-model.lazy="$v.form.sourceAccount.$model"
                />
              </div>
            </div>
          </div>
          <small
            class="error"
            v-if="submitted && !$v.form.sourceAccount.required"
            >Source account is required</small
          >
        </div>
      </section>

      <section class="deposit__details">
        <div class="heading-four">
          <h2>Amount</h2>
          <p>Withdraw amount</p>
        </div>
        <div>
          <div class="form__group--four">
            <label for="amount" class="form__label">
              <input
                type="tel"
                id="amount"
                name="amount"
                placeholder="Set amount"
                class="form__input"
                v-model.lazy="$v.form.amount.$model"
                :class="{
                  'is-invalid': submitted && $v.form.amount.$error
                }"
            /></label>
            <small class="error" v-if="submitted && !$v.form.amount.required"
              >Amount is required</small
            >
            <br />
            <small class="error" v-if="submitted && !$v.form.amount.numeric"
              >Needs to be numeric only.</small
            >
          </div>
        </div>
      </section>

      <section class="deposit__details">
        <div class="heading-four">
          <h2>Cashout Pin</h2>
          <p>Use this pin when withdrawing</p>
        </div>
        <div>
          <div class="form__group--four">
            <label for="pin">
              <input
                id="pin"
                :type="passwordFieldType"
                name="pin"
                class="form__input"
                placeholder="Set cashout pin"
                v-model="$v.form.pin.$model"
                :class="{ 'is-invalid': submitted && $v.form.pin.$error }"
            /></label>
            <span @click="switchVisibility" class="form__password">{{
              passwordFieldType === "password" ? "Show" : "Hide"
            }}</span>
            <span v-if="submitted && $v.form.pin.$error" class="error">
              <small class="error" v-if="!$v.form.pin.required"
                >Pin is required</small
              >
              <br />
              <small class="error" v-if="!$v.form.pin.numeric"
                >Needs to be numeric only.</small
              >
              <br />
              <small
                class="error"
                v-if="!$v.form.pin.maxLength || !$v.form.pin.minLength"
                >Pin must be 4 numbers.</small
              >
            </span>
          </div>
        </div>
      </section>

      <button type="submit" class="btn btn__red btn__group">
        <loading v-if="loading" />
        <span>Generate Code</span>
      </button>
    </form>
  </section>
</template>

<script>
import api from "@/api/api.js";
import { mapState } from "vuex";
import {
  required,
  numeric,
  minLength,
  maxLength
} from "vuelidate/lib/validators";

export default {
  computed: mapState(["userCardAccounts", "user", "loading"]),
  data() {
    return {
      passwordFieldType: "password",
      submitted: false,
      loading: false,
      form: {
        sourceAccount: "",
        amount: "",
        pin: ""
      }
    };
  },
  validations: {
    form: {
      sourceAccount: { required },
      amount: { required, numeric },
      pin: {
        required,
        numeric,
        minLength: minLength(4),
        maxLength: maxLength(4)
      }
    }
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    clearData() {
      this.form.sourceAccount = "";
      this.form.amount = "";
      this.form.pin = "";
      this.submitted = false;
    },
    submitForm() {
      this.submitted = true;

      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        return;
      }

      let payload = {
        SMID: this.user.profileInfo.SMID,
        amount: +this.form.amount,
        cashoutPin: this.form.pin,
        nuban: this.form.sourceAccount.NUBAN
      };

      this.loading = true;
      this.$store.commit("toggleLoading", true);

      api
        .setPickupAtm(payload)
        .then(response => {
          this.loading = false;
          this.$store.commit("toggleLoading", false);
          let responseStatus = response.Status;
          let responseMessage = response.Message;
          if (responseStatus) {
            this.$store.commit("toggleLoading", false);
            this.$message({
              showClose: true,
              message: `${responseMessage.charAt(0).toUpperCase() +
                responseMessage.slice(1)}. Paycode sent to your mobile number.`,
              type: "success",
              duration: 10000
            });
            this.clearData();
            this.$store.dispatch("handleGetUserAccounts");
          } else {
            this.$store.commit("toggleLoading", false);
            this.$message({
              showClose: true,
              message: `${responseMessage.charAt(0).toUpperCase() +
                responseMessage.slice(1)}`,
              type: "error",
              duration: 10000
            });
          }
        })
        .catch(error => {
          this.loading = false;
          this.$store.commit("toggleLoading", false);
          this.$message({
            showClose: true,
            message: `Error saving pickup atm`,
            type: "error",
            duration: 10000
          });
          throw error;
        });

      // this.$store.commit("toggleLoading", true);

      // api
      //   .handleChequeDeposit(payload)
      //   .then((response) => {
      //     let responseStatus = response.Status;
      //     let responseMessage = response.Message;

      //     if (responseStatus) {
      //       this.$alert(
      //         `${responseMessage.charAt(0).toUpperCase() +
      //           responseMessage.slice(1)}`,
      //         "Success",
      //         {
      //           confirmButtonText: "OK",
      //           type: "success",
      //           dangerouslyUseHTMLString: true,
      //         }
      //       );
      //       this.$store.dispatch("handleGetUserAccounts");
      //     } else {
      //       this.$store.commit("toggleLoading", false);
      //       this.$alert(
      //         `${responseMessage.charAt(0).toUpperCase() +
      //           responseMessage.slice(1)}`,
      //         "Error",
      //         {
      //           confirmButtonText: "OK",
      //           type: "error",
      //           dangerouslyUseHTMLString: true,
      //         }
      //       );
      //     }
      //   })
      //   .catch((error) => {
      //     this.$store.commit("toggleLoading", false);
      //     this.$message({
      //       showClose: true,
      //       message: `${error}`,
      //       type: "error",
      //       duration: 10000,
      //     });
      //   });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_form.scss";
</style>
